import { configLabel, rules } from "@kanpla/system";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import React, { Dispatch, KeyboardEventHandler, SetStateAction } from "react";

interface Props {
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  autoFocus?: HTMLInputElement["autofocus"];
}

const PasswordInput = ({
  password,
  setPassword,
  onKeyDown = () => null,
  autoFocus,
}: Props) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Form.Item
      className="relative form-label-antd-layout form-label-antd-h-unset"
      name="password"
      label={
        <div className="flex items-end justify-between">
          <span>{t("translation:password")}</span>
          <Link href="/resetPassword">
            <button
              type="button"
              className="absolute right-0 top-0 pt-2 h100 font-medium hover:underline cursor-pointer focus:shadow-none focus:underline"
            >
              {t("translation:forgot")}
            </button>
          </Link>
        </div>
      }
      labelCol={configLabel({ span: 24 })}
      initialValue={password}
      rules={[
        rules({
          rule: "required",
          ruleValue: true,
          message: t("translation:form.errors.required", {
            value: t("translation:password").toLowerCase(),
          }),
        }),
      ]}
    >
      <Input
        id="password"
        type="password"
        placeholder={`${t("translation:password")}...`}
        autoFocus={autoFocus}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </Form.Item>
  );
};

export default PasswordInput;
