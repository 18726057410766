import { AnonymousPropsFlow } from "@kanpla/types";
import { callFunction, configLabel, KanplaError } from "@kanpla/system";
import { Form, Input, message, Space } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { default as React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ButtonSignUp from "../signup-flow/ButtonSignUp";
import PasswordInput from "./partial/PasswordInput";
import Posone from "./Posone";
import LoginInner from "./Posone/LoginInner";

const BasicLogin = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["login", "translation"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { auth, customBranding, setSchoolId, setModuleId } =
    useContainer(AppContext);

  const [posoneOpen, setPosoneOpen] = useState(false);

  const router = useRouter();

  const signIn = async () => {
    try {
      setLoading(true);

      if (!email || email === "")
        throw new KanplaError("kanpla/fill-email", "Fill out email address");

      await callFunction("signup-canAccessPartner", {
        email,
        partnerId: customBranding?.partnerId,
      });

      setSchoolId(null);
      setModuleId(null);
      await auth.signIn(email, password);

      return true;
    } catch (err) {
      // User isn't created
      if (err?.code === "functions/not-found") {
        const noUserError = new KanplaError(
          "auth/user-not-found",
          "Firebase: Error (auth/user-not-found)."
        );

        message.error(noUserError.message);
        return;
      }

      // Can't log user in
      if (err?.code === "functions/unauthenticated") {
        console.error(err);
        message.error(t("translation:message.error.partner-locked"));
        return;
      }

      const newError = new KanplaError(err?.code, err?.message);
      console.error(err);
      message.error(newError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="h700">{t("translation:welcome-back")}</h1>
      <p className="text-text-secondary">
        {t("translation:log-in-to-your-account")}
      </p>
      <Form
        className="my-8 max-w-xs m-auto"
        onFinish={signIn}
        // To catch the submit event on a custom form
        onSubmitCapture={(event) => event.preventDefault()}
      >
        {!customBranding?.posone && (
          <>
            <Form.Item
              name="email"
              label="Email"
              labelCol={configLabel({ span: 24 })}
              rules={[
                {
                  required: true,
                  message: t("translation:form.errors.required", {
                    value: "email",
                  }),
                },
                {
                  type: "email",
                  message: t("translation:form.errors.invalid", {
                    value: "email",
                  }),
                },
              ]}
              initialValue={email}
            >
              <Input
                id="email"
                placeholder="Email..."
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <PasswordInput password={password} setPassword={setPassword} />
          </>
        )}

        <Space direction="vertical" className="w-full">
          {!customBranding?.posone && (
            <Form.Item>
              <ButtonSignUp
                title={t("translation:log-in")}
                loading={loading}
                loadingText={t("translation:loading")}
              />
            </Form.Item>
          )}
          {customBranding?.posone && customBranding?.url && (
            <LoginInner
              domain={customBranding?.url}
              name={customBranding?.name}
            />
          )}
          {customBranding?.posone && !customBranding?.url && (
            <ButtonSignUp
              title={t("login:login-with-smart-payment")}
              onClick={() => setPosoneOpen(true)}
              type="button"
            />
          )}
        </Space>
        <Posone open={posoneOpen} setOpen={setPosoneOpen} />
        {!customBranding?.posone && (
          <div className="mt-3">
            {t("translation:dont-have-account")}{" "}
            <Link
              href={{
                pathname: `/signup`,
                query: router.query,
              }}
            >
              <a className="font-semibold text-primary-main">
                {t("translation:get-one")}
              </a>
            </Link>
          </div>
        )}
      </Form>
    </>
  );
};

export default BasicLogin;
